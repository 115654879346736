
import React, {useState} from 'react';

function AddUserActivity(props) {
    const [activityName, setActivityName] = useState(props.defaultName);
    const [activityMeasure, setActivityMeasure] = useState(props.defaultMeasure);
    const [activityMeasureName, setActivityMeasureName] = useState(props.defaultMeasureName);
    const [activityInitialLoad, setActivityInitialLoad] = useState(props.defaultInitialLoad);
    const [activityTargetLoad, setActivityTargetLoad] = useState(props.defaultTargetLoad);
    const [activityPreTimelineDays, setActivityPreTimelineDays] = useState(props.defaultPreTimelineDays);
    const [activityPostTimelineDays, setActivityPostTimelineDays] = useState(props.defaultPostTimelineDays);

    const[hasValidationErrors, setHasValidationErrors] = useState(false);
    const[validationErrors, setValidationErrors] = useState([]);

    const handleAddClick = () => {
        let validationErrors = [];
        let hasValidationErrors = false;

        if(!activityName) {
            validationErrors.push("Activity name is required");
            hasValidationErrors = true;
        }

        if(!activityMeasureName){
            validationErrors.push("Measurement label is required");
            hasValidationErrors = true;
        }

        setHasValidationErrors(hasValidationErrors)
        setValidationErrors(validationErrors);

        if (!hasValidationErrors) {
            props.onAdd(activityName, activityMeasure, activityMeasureName, activityPreTimelineDays, activityPostTimelineDays, activityInitialLoad, activityTargetLoad); 
            props.onClose();
        }
    }
    return (<>
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Activity Settings</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" onSubmit={(e) => e.preventDefault()}>
                        {hasValidationErrors &&
                            <div className="alert alert-danger" role="alert">
                                <strong>Please correct the following errors</strong>
                                <ul>
                                    {validationErrors.map((e) => <li>{e}</li>)}
                                </ul>
                            </div>
                        }
                        <div className="form-row">
                            <div className="col-xl-12 col-md-12 mb-3">
                                <label htmlFor="activityName">Activity Name</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" required id="activityName" value={activityName} onChange={(e) => {setActivityName(e.target.value)}} />
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="activityMeasure">Measure</label>
                                <div className="input-group">
                                    <select className="form-control" required id="activityMeasure" value={activityMeasure} onChange={(e) => {setActivityMeasure(e.target.value)}}>
                                        <option value="Reps">Repetitions</option>
                                        <option value="Distance">Distance</option>
                                        <option value="Time">Time</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="measureLabel">Measurement label</label>
                                <div className="input-group">
                                    <input type="text" class="form-control" required id="measureLabel" value={activityMeasureName} onChange={(e) => {setActivityMeasureName(e.target.value)}} />
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="beforeDays">Historical timeline</label>
                                <div className="input-group">
                                    <select className="form-control" value={activityPreTimelineDays} onChange={(e) => {setActivityPreTimelineDays(e.target.value)}} id="beforeDays">
                                        <option value="7">1 week</option>
                                        <option value="14">2 weeks</option>
                                        <option value="28">4 weeks</option>
                                        <option value="42">6 weeks</option>
                                        <option value="56">8 weeks</option>
                                        <option value="84">12 weeks</option>
                                        <option value="180">6 months</option>
                                        <option value="360">1 year</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="beforeDays">Future timeline</label>
                                <div className="input-group">
                                    <select className="form-control" value={activityPostTimelineDays} onChange={(e) => {setActivityPostTimelineDays(e.target.value)}} id="afterDays">
                                        <option value="7">1 week</option>
                                        <option value="14">2 weeks</option>
                                        <option value="28">4 weeks</option>
                                        <option value="42">6 weeks</option>
                                        <option value="56">8 weeks</option>
                                        <option value="84">12 weeks</option>
                                        <option value="180">6 months</option>
                                        <option value="360">1 year</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="initialLoad">Initial load</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="initialLoad" required value={activityInitialLoad} onChange={(e) => {setActivityInitialLoad(e.target.value)}} />
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="initialLoad">Target load</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="initialLoad"  value={activityTargetLoad} onChange={(e) => {setActivityTargetLoad(e.target.value)}} />
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-success float-right" type="submit" onClick={handleAddClick}>Add</button>
                        <button className="btn btn-secondary float-left" onClick={props.onClose}>Close</button>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default AddUserActivity;