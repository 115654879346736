import React from 'react';
import { Link } from "react-router-dom";

function UserActivityPanel(props) {
    return (<div className="">
        <Link className="btn btn-block btn-square btn-outline-primary" to={`/activities/${props.userActivityID}`} style={{color: "#FFFFFF", position: 'relative'}}>
        <div >{props.activityName}</div>
        <div style={{textAlign: 'right', position: 'absolute', right: '0px', top:'0px'}} >
            {props.onDelete && 
                <button className="ms-btn-icon-outline btn-square btn-light" style={{border: 'none'}} onClick={(e) => {
                    e.preventDefault(); // We're inside a link, so we need to prevent that click redirecting us
                    window.confirm(`Are you sure you want to delete "${props.activityName}"?`) && props.onDelete()}}>
                        <i className="material-icons fs-16">delete</i>
                </button>
            }
        </div>
        </Link>
        </div>
    );
}

export default UserActivityPanel;
