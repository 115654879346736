let servicesHost = "http://localhost:7072/api";
let auth0 = {
    domain: "dev-loadtracker.au.auth0.com",
    host : "https://dev-loadtracker.au.auth0.com", 
    redirect: "http://localhost:3000", 
    clientId : "klmD8adg7xYXtKZwWnVGAoPTfc6WnOYT"
};

const hostname = window && window.location && window.location.hostname;

if(hostname === 'loadtracker.practiceloop.com') {
    servicesHost = "https://loadtracker-eus.azurewebsites.net/api";
    auth0.redirect = "https://loadtracker.practiceloop.com"
}

if(hostname === 'uat.x-tracked.com.au') {
    servicesHost = "https://loadtracker-eus.azurewebsites.net/api";
    auth0.redirect = "https://uat.x-tracked.com.au"
}

if(hostname === 'www.x-tracked.com.au') {
    servicesHost = "https://loadtracker-eus.azurewebsites.net/api";
    auth0.redirect = "https://www.x-tracked.com.au"
}

export {servicesHost, auth0}