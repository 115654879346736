
import React, { useState } from 'react';

function AddActivity(props) {
    const [activityDate, setActivityDate] = useState(props.defaults ? props.defaults.Time : "");
    const [practiceValue, setPracticeValue] = useState(props.defaults ? props.defaults.PracticeValue : "");
    const [competitiveValue, setCompetitiveValue] = useState(props.defaults ? props.defaults.CompetitiveValue : "");
    const [isPlanned, setIsPlanned] = useState(props.defaults ? props.defaults.IsPlanned : false);
    const [flag, setFlag] = useState(props.defaults ? props.defaults.Flag : "");
    const [comment, setComment] = useState(props.defaults ? props.defaults.Comment : "");

    const[hasValidationErrors, setHasValidationErrors] = useState(false);
    const[validationErrors, setValidationErrors] = useState([]);

    const handleAddClick = () => {
        let validationErrors = [];
        let hasValidationErrors = false;

        if(!activityDate) {
            validationErrors.push("Activity date is required");
            hasValidationErrors = true;
        }

        setHasValidationErrors(hasValidationErrors)
        setValidationErrors(validationErrors);

        if (!hasValidationErrors) {
            props.onAdd(activityDate, practiceValue, competitiveValue, isPlanned, flag, comment);
            props.onClose();
        }
    }

    return (<>
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Add {props.activityContext.name} activity</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate onSubmit={(e) => e.preventDefault()}>
                    {hasValidationErrors &&
                            <div className="alert alert-danger" role="alert">
                                <strong>Please correct the following errors</strong>
                                <ul>
                                    {validationErrors.map((e) => <li>{e}</li>)}
                                </ul>
                            </div>
                        }
                        <div className="form-row">
                            <div className="col-12">
                                <label htmlFor="activityName">Date</label>
                                <div className="input-group">
                                    <input type="date" className="form-control" required id="activity-date" value={activityDate} onChange={(e) => setActivityDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="activity-practice-balls">Practice {props.activityContext.measure_unit_name}</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="activity-practice-balls" value={practiceValue} onChange={(e) => setPracticeValue(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="activity-comp-balls">Competition {props.activityContext.measure_unit_name}</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="activity-comp-balls" value={competitiveValue} onChange={(e) => setCompetitiveValue(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <label>Type</label>
                                <ul className="ms-list d-flex">
                                    <li className="ms-list-item pl-0">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio" name="isPlanned" value={true} checked={isPlanned} onChange={(e) => setIsPlanned(e.target.value === "true")} />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Planned </span>
                                    </li>
                                    <li className="ms-list-item">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio" name="isPlanned" value={false} checked={!isPlanned} onChange={(e) => setIsPlanned(e.target.value === "true")} />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Actual </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 image-radio-group">
                                <label>Tags</label>
                                <div>
                                <label className="fs-10">
                                    <input type="radio" name="flag" value="Good" checked={flag === "Good"} onChange={(e) => setFlag(e.target.value)} />
                                    <i className="material-icons" style={{color: '#009900'}}>mood</i><br/>
                                    Good
                                </label>

                                <label className="fs-10">
                                    <input type="radio" name="flag" value="Sore" checked={flag === "Sore"} onChange={(e) => setFlag(e.target.value)} />
                                    <i className="material-icons" style={{color: '#FFA500'}} >personal_injury</i><br />
                                    Sore
                                </label>
                                <label className="fs-10">
                                    <input type="radio" name="flag" value="Injured" checked={flag === "Injured"} onChange={(e) => setFlag(e.target.value)} />
                                    <i className="material-icons" style={{color: '#CC0000'}} >health_and_safety</i><br />
                                    Injured
                                </label>
                                </div>
                            </div>
                            <div className="col-12">
                            <label htmlFor="activityName">Comment</label>
                                <div className="input-group">
                                    <textarea className="form-control" id="activity-comment" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-success float-right" type="submit" onClick={handleAddClick}>Add</button>
                        <button className="btn btn-secondary float-left" onClick={props.onClose}>Close</button>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default AddActivity;