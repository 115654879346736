import { call, takeLatest, select, put } from 'redux-saga/effects';
import { servicesHost } from '../environment';
import { setActivityDetails, setUserActivityShare, removeUserActivityShareDone } from '../actions/activityDetailAction'
import GQLClient from '../data/graphQLClient'
const { Actions } = require("../constants");

function* fetchActivityDetail() {
    yield takeLatest(Actions.ActivityDetail.FETCH_ACTIVITY_DETAIL, onFetchActivityDetails);
}

function* createActivity() {
    yield takeLatest(Actions.ActivityDetail.CREATE_ACTIVITY, onCreateActivity);
}

function* createActivities() {
    yield takeLatest(Actions.ActivityDetail.CREATE_USER_ACTIVITIES, onCreateActivities);
}

function* createUserActivityShare() {
    yield takeLatest(Actions.ActivityDetail.CREATE_USER_ACTIVITY_SHARE, onCreateUserActivityShare);
}

function* removeUserActivityShare() {
    yield takeLatest(Actions.ActivityDetail.REMOVE_USER_ACTIVITY_SHARE, onRemoveUserActivityShare);
}

function* onRemoveUserActivityShare(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const token = yield select(getToken);
        var repo = new GQLClient(`${servicesHost}/graphql`, token);
        yield call(() => repo.removeUserActivityShare(action.sharePubId));
        yield put(removeUserActivityShareDone(action.sharePubId));
    }
    catch (e) {
        return;
    }
}

function* onCreateUserActivityShare(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const token = yield select(getToken);
        var repo = new GQLClient(`${servicesHost}/graphql`, token);
        let share = yield call(() => repo.createUserActivityShare(action.shareInfo));
        yield put(setUserActivityShare(share));
    }
    catch (e) {
        return;
    }
}

function* onFetchActivityDetails(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const token = yield select(getToken);
        var repo = new GQLClient(`${servicesHost}/graphql`, token);
        let userActivityData = yield call(() => repo.getUserActivity(action.userActivityId));
        yield put(setActivityDetails(userActivityData));
    }
    catch (e) {
        return;
    }
}

function* onCreateActivities(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const jwt = yield select(getToken);

        var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
        yield call(() => repo.createActivities(action.activities));

        let userActivityData = yield call(() => repo.getUserActivity(action.userActivityPubID));
        yield put(setActivityDetails(userActivityData));
    }
    catch (e) {
        return;
    }
}

function* onCreateActivity(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const jwt = yield select(getToken);

        action.activity.practiceValue = parseFloat(action.activity.practiceValue, 2) ?? 0.0;
        action.activity.competitiveValue = parseFloat(action.activity.competitiveValue, 2) ?? 0.0;
        action.activity.isPlanned = action.activity.isPlanned || action.activity.isPlanned === 'true';
        action.activity.flag = action.activity.flag === '' ? null : action.activity.flag;

        var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
        yield call(() => repo.createActivity(action.activity));

        let userActivityData = yield call(() => repo.getUserActivity(action.activity.userActivityPubId));
        yield put(setActivityDetails(userActivityData));
    }
    catch (e) {
        return;
    }
}

export { fetchActivityDetail, createActivity, createUserActivityShare, createActivities, removeUserActivityShare }