import { call, takeLatest, select, put } from 'redux-saga/effects';
import {servicesHost} from '../environment';
import {setUserInfo, setShareInvitations} from '../actions/userInfoAction'
import { setActivityDetails } from '../actions/activityDetailAction'
import GQLClient from '../data/graphQLClient'

const { Actions } = require("../constants");

function* fetchUserInfo() {
    yield takeLatest(Actions.UserInfo.FETCH_USER_INFO, onFetchUserInfo);
}

function* createUserActivity() {
    yield takeLatest(Actions.UserInfo.CREATE_USER_ACTIVITY, onCreateUserActivity);
}

function* deleteUserActivity() {
    yield takeLatest(Actions.UserInfo.DELETE_USER_ACTIVITY, onDeleteUserActivity);
}

function* updateUserActivity() {
    yield takeLatest(Actions.UserInfo.UPDATE_USER_ACTIVITY, onUpdateUserActivity);
}

function* acceptShareInvitation() {
    yield takeLatest(Actions.UserInfo.ACCEPT_SHARE_INVITATION, onAcceptShareInvitation);
}

function* onFetchUserInfo() {
    const getToken = (state) => state.userInfo.token;
    try {
        const token = yield select(getToken);
        var userInfo = yield call(() => fetchUserInfoData(token));
        yield put(setUserInfo(userInfo));
    }
    catch (e) {
        return;
    }
}

function* onAcceptShareInvitation(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const jwt = yield select(getToken);
        var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
        var shares = yield call(() => repo.acceptShareInvitation(action.sharePubID));
        yield put(setShareInvitations(shares));
    }
    catch (e) {
        return;
    }
}

function* onCreateUserActivity(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const token = yield select(getToken);
        yield call(() => executeCreateUserActivity(token, action.userActivity));
        var userInfo = yield call(() => fetchUserInfoData(token));
        yield put(setUserInfo(userInfo));
    }
    catch (e) {
        return;
    }
}

function* onDeleteUserActivity(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const jwt = yield select(getToken);
        var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
        yield call(() => repo.deleteUserActivity(action.userActivityPubID));
        var userInfo = yield call(() => fetchUserInfoData(jwt));
        yield put(setUserInfo(userInfo));
    }
    catch (e) {
        return;
    }
}

function* onUpdateUserActivity(action) {
    const getToken = (state) => state.userInfo.token;
    try {
        const jwt = yield select(getToken);
        
        console.log(action);

        var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
        yield call(() => repo.updateUserActivity(action.userActivity));

        let userActivityData = yield call(() => repo.getUserActivity(action.userActivity.userActivityPubId));
        yield put(setActivityDetails(userActivityData));
    }
    catch (e) {
        return;
    }
}

function executeCreateUserActivity(jwt, userActivity) {
    var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
    return repo.createUserActivity(userActivity);
}

function fetchUserInfoData(jwt) {
    var repo = new GQLClient(`${servicesHost}/graphql`, jwt);
    return repo.getUserInfo();
}

export {fetchUserInfo, createUserActivity, updateUserActivity, acceptShareInvitation, deleteUserActivity}