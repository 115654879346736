import { Actions } from "../constants";


const userInfo = (state = {}, action) => {
  switch (action.type) {
    case Actions.UserInfo.SET_USER_INFO:
      let setUserInfoState = Object.assign({}, state);
      setUserInfoState.loading = action.loading;
      setUserInfoState.firstName = action.userInfo.FirstName;
      setUserInfoState.lastName = action.userInfo.LastName;
      setUserInfoState.userActivities.limit = action.userInfo.UserActivityLimit;
      setUserInfoState.userActivities.items = action.userInfo.UserActivities;
      setUserInfoState.sharedWithMe.items = action.userInfo.SharedWith;
      return setUserInfoState;

    case Actions.UserInfo.SET_SHARE_INVITATIONS:
      let setShareInvitationsState = Object.assign({}, state);
      setShareInvitationsState.sharedWithMe.items = action.shares;
      return setShareInvitationsState;

    case Actions.UserInfo.SET_USER_IDENTIFIER:
      let setUserIdState = Object.assign({}, state);
      setUserIdState.id = action.userId;
      return setUserIdState;

    case Actions.UserInfo.SET_ID_TOKEN:
      let setIdTokenState = Object.assign({}, state);
      setIdTokenState.token = action.token;
      return setIdTokenState;

    case Actions.UserInfo.CREATE_USER_ACTIVITY:
      let createUserActivityState = Object.assign({}, state);
      createUserActivityState.loading = true;
      return createUserActivityState;

    case Actions.UserInfo.DELETE_USER_ACTIVITY:
      let deleteUserActivityState = Object.assign({}, state);
      deleteUserActivityState.loading = true;
      return deleteUserActivityState;
    default:
      return state;
  }
}

export default userInfo;
