import React, { useEffect } from 'react';
import {auth0} from '../environment';
import { clearTokens } from '../session';

function Logout() {

  const logout = () => {
    clearTokens();
    window.location.href = `${auth0.host}/v2/logout?client_id=${auth0.clientId}&returnTo=${auth0.redirect}`;
  }

  useEffect(() => {
    logout();
  }, [logout]);

  return (<>Logging out</>
  );
}

export default Logout;
