import Cookies from 'js-cookie';

export const getIdToken = () => {
    let token = sessionStorage.getItem("idt");
    if (!token) {
        token = Cookies.get("idt");
    }
    return token;
}

export const getRefreshToken = () => {
    let token = localStorage.getItem("rt");
    if (!token) {
        token = Cookies.get("rt");
    }
    return token;
}

export const setIdToken = (value) =>  {
    Cookies.set("idt", value);
    sessionStorage.setItem("idt", value);
}

export const setRefreshToken = (value) => {
    Cookies.set("rt", value);
    localStorage.setItem("rt", value);
}

export const clearTokens = () => {
    sessionStorage.removeItem("idt");
    localStorage.removeItem("rt");
    Cookies.remove("idt");
    Cookies.remove("rt");
}

export const isLoggedIn = () => {   
    let idToken = getIdToken();
    return !!idToken;
}
