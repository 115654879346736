import React from 'react';
import {Link} from 'react-router-dom'
import {auth0} from '../environment';
import { clearTokens } from '../session';

const logout = () => {
    clearTokens();
    window.location.href = `${auth0.host}/v2/logout?client_id=${auth0.clientId}&returnTo=${auth0.redirect}`;
  }

function LeftMenu(props) {
    let className = props.open ? "side-nav fixed ms-aside-scrollable ms-aside-left ms-aside-open" : "side-nav fixed ms-aside-scrollable ms-aside-left"; 
    return (<>
        <aside id="ms-side-nav" className={className}>
            <div className="logo-sn ms-d-block-lg">
                <a className="pl-0 ml-0 text-center" href="pages/dashboard/index.html"> X-Tracked </a>
            </div>

            <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                <li className="menu-item">
                    <Link to="/">
                        <span><i className="material-icons fs-16">widgets</i>Home</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <a href="#" onClick={logout}>
                        <span><i className="material-icons fs-16">widgets</i>Log out</span>
                    </a>
                </li>
            </ul>
        </aside>
    </>
    );
}

export default LeftMenu;
