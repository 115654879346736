import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import rootReducer from './reducers';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import createSagaMiddleware from 'redux-saga'

import UserViewContainer from './containers/UserViewContainer';
import ActivityContainer from './containers/ActivityContainer';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { createActivity, createActivities, fetchActivityDetail, createUserActivityShare, removeUserActivityShare } from './sagas/activityDetailSaga'
import {fetchUserInfo, createUserActivity, updateUserActivity, acceptShareInvitation, deleteUserActivity} from './sagas/userInfoSaga'
import {fetchIdToken, refreshIdToken} from './sagas/authTokenSaga'
import Login from './components/Login';
import Logout from './components/Logout';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

let store = createStore(rootReducer, {
  userInfo: {
    loading: true,
    firstName: "",
    lastName: "",
    token: "",
    userActivities: {
      limit: 0,
      items:[]
    },
    sharedWithMe: {
      items:[]
    }
  },
  activityDetail: {
    loading: true,
    name: "",
    measure_unit: "",
    measure_unit_name: "",
    activities:[],
    shares:[]
  },
},
composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(fetchUserInfo);
sagaMiddleware.run(createUserActivity);
sagaMiddleware.run(updateUserActivity);
sagaMiddleware.run(fetchActivityDetail);
sagaMiddleware.run(fetchIdToken);
sagaMiddleware.run(refreshIdToken);
sagaMiddleware.run(createActivity);
sagaMiddleware.run(createActivities);
sagaMiddleware.run(createUserActivityShare);
sagaMiddleware.run(acceptShareInvitation);
sagaMiddleware.run(deleteUserActivity);
sagaMiddleware.run(removeUserActivityShare);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <h1>Oops</h1>,
    children: [
      {
        path: "/",
        element: <UserViewContainer />
      },
      {
        path: "/activities/:activityID",
        element: <ActivityContainer />
      }
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/logout",
    element: <Logout />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
