import Cookies from 'js-cookie';
import { put, call, takeLatest} from 'redux-saga/effects';
import { setIdToken } from '../actions/userInfoAction';
import { auth0, servicesHost } from '../environment';
import { Actions } from '../constants';

import axios from 'axios';


/////// EXPORTED SAGAS /////////

// TOKENS
function* fetchIdToken() {
  yield takeLatest(Actions.UserInfo.FETCH_ID_TOKEN_FROM_CODE, onFetchToken);
}

function* refreshIdToken() {
  yield takeLatest(Actions.UserInfo.FETCH_ID_TOKEN_FROM_REFRESH, onFetchToken);
}

/////// END EXPORTED SAGAS /////////



function* onFetchToken(action) {
  let response = "";
  let type = "";
  let code = "";

  if (action.code) // We have a code, so exchange that for a token
  {
    type = "authorization_code";
    code = action.code;
  }
  else if (action.refreshToken) {
    type = "refresh_token";
    code = action.refreshToken;
  }
  else {
    return;
  }

  try {
    response = yield call(() => getTokenFromCode(code, type));
    Cookies.set("rt", response.refresh);
    yield put(setIdToken(response.token));
  }
  catch (e) {
    //yield put(getIdTokenFailed(e));
    return;
  }
}

function getTokenFromCode(code, type) {
  return axios({
    method: "post",
    url: `${servicesHost}/token`,
    data: { "code": code, "redirect_uri": auth0.redirect, "type" : type },
    headers: { "Content-Type": "application/json" }
  }).then(({ data }) => {
    return data;
  })
}


export { fetchIdToken, refreshIdToken }