import { connect } from 'react-redux'
import UserView from '../components/UserView'
import { fetchUserInfo, createUserActivity, acceptShareInvitation, deleteUserActivity } from '../actions/userInfoAction'

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserInfo: () => {
          dispatch(fetchUserInfo())
        },
        onAddUserActivity: (name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => {
          initialLoad = parseInt(initialLoad);
          preDays = parseInt(preDays);
          postDays = parseInt(postDays);
          targetLoad = parseFloat(targetLoad);

          dispatch(createUserActivity(name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad))
        },
        onDeleteUserActivity: (userActivityID) => {
          dispatch(deleteUserActivity(userActivityID));
        },
        onAcceptShare: (sharePubID) => {
          dispatch(acceptShareInvitation(sharePubID))
        }
    }
  }

const mapStateToProps = (state = {})  => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserView)