import {getUserInfo, createUserActivity, updateUserActivity, acceptShareInvitation, deleteUserActivity} from './graphqlQueries/userInfoQueries'
import {createActivity, createActivities, getUserActivity, createUserActivityShare, removeUserActivityShare} from './graphqlQueries/activityQueries'


export default class GQLClient {

    constructor(uri, token) {
        this.uri = uri;
        this.token = token;
    }

    async getUserInfo() {
        let data = await this.query(getUserInfo);
        return data.user;
    }

    async getUserActivity(userActivityPubId) {
        let data = await this.query(getUserActivity, {userActivityPubId});
        return data.userActivity;
    }

    async createUserActivity(activity) {
        let data = await this.query(createUserActivity, 
            {
                name: activity.name, 
                measure_unit: activity.measure, 
                measure_unit_name: activity.measureLabel, 
                timeline_pre_days: activity.preDays,
                timeline_post_days: activity.postDays,
                starting_load: activity.initialLoad,
                target_load: activity.targetLoad
            });
        return data.user;
    }

    async deleteUserActivity(userActivityPubId) {
        let data = await this.query(deleteUserActivity, 
            {
                pub_id: userActivityPubId
            });
        return data.PubID;
    }

    async updateUserActivity(activity) {
        let data = await this.query(updateUserActivity, 
            {
                user_activity_pub_id: activity.userActivityPubId,
                name: activity.name, 
                measure_unit: activity.measure, 
                measure_unit_name: activity.measureLabel, 
                timeline_pre_days: activity.preDays,
                timeline_post_days: activity.postDays,
                starting_load: activity.initialLoad,
                target_load: activity.targetLoad
            });
        return data.user;
    }

    async createActivity(activity) {
        let data = await this.query(createActivity, 
            {
                userActivityPubId: activity.userActivityPubId,
                time: activity.date,    
                practice_value: activity.practiceValue,
                competitive_value: activity.competitiveValue,
                is_planned: activity.isPlanned,
                flag: activity.flag,
                comment: activity.comment
            });
        return data.addActivity;
    }

    async createActivities(activities) {
        let data = await this.query(createActivities, 
            {
                activities: activities
            });
        return data.addActivities;
    }
    

    async createUserActivityShare(shareInfo){
        let data = await this.query(createUserActivityShare,
            {
                userActivityPubId: shareInfo.userActivityPubId,
                shareToEmail: shareInfo.shareToEmail,
                readOnly: shareInfo.readOnly
            });

        return data.addShareInvitation;
    }

  async removeUserActivityShare(sharePubId){
    let data = await this.query(removeUserActivityShare,
      {
        sharePubId: sharePubId,
      });

    return data.removeUserActivityShare;
  }


    async acceptShareInvitation(sharePubId){
        let data = await this.query(acceptShareInvitation,
            {
                pub_id: sharePubId
            });

        return data.acceptShareInvitation;
    }

    async query(query, variables) {
        return await fetch(this.uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${await this.token}`,
            },
            body: JSON.stringify({ 
                query: query,
                variables: variables 
            })
        })
            .then(r => {
                if (r.status === 500) {
                    throw Error(`Http error: ${r.status}. ${r.statusText}`); 
                }
                return r.json();
            })
            .then(json => { 
                if (json.errors !== undefined){
                    for (let e of json.errors) {
                        if(e.extensions.code === "UNAUTHENTICATED"){
                            document.location.href = "/login";
                            return;
                        }
                    }
                    throw Error(json.errors.map(e => e.message).join(","))
                }
                return json.data })
            .catch(e => {
                console.log(e)
                throw(e);
            });
    }
}