import { Actions } from "../constants";

export const fetchUserInfo = () => ({
    type: Actions.UserInfo.FETCH_USER_INFO,
    loading: true
})

export const setUserInfo = (userInfo) => ({
    type: Actions.UserInfo.SET_USER_INFO,
    loading:false,
    userInfo
})
export const fetchIdToken = (code) => ({
    type: Actions.UserInfo.FETCH_ID_TOKEN_FROM_CODE,
    code
})
export const refreshIdToken = (refreshToken) => ({
    type: Actions.UserInfo.FETCH_ID_TOKEN_FROM_REFRESH,
    refreshToken
})

export const setIdToken = (token) => ({
    type: Actions.UserInfo.SET_ID_TOKEN,
    token
})

export const createUserActivity = (name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => ({
    type: Actions.UserInfo.CREATE_USER_ACTIVITY,
    userActivity : {name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad}
})

export const updateUserActivity  = (userActivityPubId, name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => ({
    type: Actions.UserInfo.UPDATE_USER_ACTIVITY,
    userActivity : {userActivityPubId, name, measure, measureLabel, preDays, postDays, initialLoad, targetLoad}
})

export const deleteUserActivity = (userActivityPubID) => ({
    type: Actions.UserInfo.DELETE_USER_ACTIVITY,
    userActivityPubID
})

export const acceptShareInvitation = (sharePubID) => ({
    type: Actions.UserInfo.ACCEPT_SHARE_INVITATION,
    sharePubID
})

export const setShareInvitations = (shares) => ({
    type: Actions.UserInfo.SET_SHARE_INVITATIONS,
    shares
})
