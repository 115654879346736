const getUserInfo = `
    query GetUserInfo {
        user{
            UserActivityLimit
            UserActivities{
                PubID
                Name
            }
            SharedWith{
                PubID
                ReadOnly
                Pending
                SharedTo
                UserActivity{
                    PubID
                    Name
                    User{
                        Email
                    }
                }
            }
        }
    }`;

const createUserActivity = `
    mutation CreateUserActivity($name: String!, $measure_unit: String!, $measure_unit_name: String!, $timeline_pre_days: Int!, $timeline_post_days: Int!,$starting_load: Int,$target_load: Float) {
        addUserActivity(Name: $name, 
            MeasureUnit: $measure_unit,
            MeasureUnitName: $measure_unit_name,
            TimeLinePreDays: $timeline_pre_days,
            TimeLinePostDays: $timeline_post_days,
            StartingLoad: $starting_load,
            TargetLoad: $target_load
        ){
            PubID
        }
    }`;

    const updateUserActivity = `
    mutation UpdateUserActivity($user_activity_pub_id: String!, $name: String!, $measure_unit: String!, $measure_unit_name: String!, $timeline_pre_days: Int!, $timeline_post_days: Int!,$starting_load: Int,$target_load: Float) {
        updateUserActivity(UserActivityPubID: $user_activity_pub_id,
            Name: $name, 
            MeasureUnit: $measure_unit,
            MeasureUnitName: $measure_unit_name,
            TimeLinePreDays: $timeline_pre_days,
            TimeLinePostDays: $timeline_post_days,
            StartingLoad: $starting_load,
            TargetLoad: $target_load
        ){
            PubID
        }
    }`;

const acceptShareInvitation = `
    mutation AcceptShare($pub_id: String!) {
        acceptShareInvitation(PubID: $pub_id){
            PubID
                ReadOnly
                Pending
                SharedTo
                UserActivity{
                    PubID
                    Name
                    User{
                        Email
                    }
                }
        }
    }`;

    const deleteUserActivity = `
    mutation DeleteUserActivity($pub_id: String!) {
        deleteUserActivity(PubID: $pub_id){
            PubID
        }
    }`;

export { getUserInfo, createUserActivity, updateUserActivity, acceptShareInvitation, deleteUserActivity }