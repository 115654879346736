import React from 'react';
import {Link} from 'react-router-dom'

function BreadCrumb(props) {
    if(props.breadCrumbs && props.breadCrumbs.length > 0){
        let crumbs = props.breadCrumbs.map((crumb, index ) => {
            if(index === props.breadCrumbs.length - 1){
                return <li key={index} className="breadcrumb-item active" aria-current="page"><Link to={crumb.url}>{crumb.title}</Link></li>
            }
            return <li key={index} className="breadcrumb-item"><Link to={crumb.url}>{crumb.title}</Link></li>
        });

        return (
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pl-0">
                        {crumbs}
                    </ol>
                    </nav>
                )
    }

        return <></>
}

export default BreadCrumb;

