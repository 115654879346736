import React, {useEffect, useState, useCallback} from 'react';
import { connect } from 'react-redux'
import './App.css';

import { Outlet } from "react-router-dom";

import LeftMenu from './components/LeftMenu';
import Login from './components/Login';

import Cookies from 'js-cookie';

import {fetchUserInfo, fetchIdToken, refreshIdToken} from './actions/userInfoAction'

function App(props) {
  

  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let bodyClass = menuOpen ? "App ms-aside-left-open" : "App";
  let overlayClass = menuOpen ? "ms-aside-overlay ms-overlay-left ms-toggler d-block" : "ms-aside-overlay ms-overlay-left ms-toggler";


  const {
    fetchUserInfo,
    fetchIdToken,
    refreshIdToken
  } = props;


  const checkLogin = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const isAuth0Redirect = params.has("code") && params.has("state");
    const refreshToken = Cookies.get("rt");
  
    if (isAuth0Redirect) {
      fetchIdToken(params.get("code"));
      window.history.replaceState({}, document.title, "/");
    }
    else if (refreshToken) {
      refreshIdToken(refreshToken);
    }
  }, [fetchIdToken, refreshIdToken]);
 
  useEffect(() => {
    checkLogin();
    setIsLoading(false);
  },[checkLogin, fetchUserInfo]);

  if (isLoading) {
    return <div></div>
  }

  return (
    <div className={bodyClass}>
      <div className={overlayClass} data-target="#ms-side-nav" data-toggle="slideLeft" onClick={() => setMenuOpen(!menuOpen)}></div>
      <LeftMenu open={menuOpen}/>
      
      { props.userInfo && props.userInfo.token !== "" ?
      <main className="body-content">

        <nav className="navbar ms-navbar">
          <div className="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="ms-toggler-bar bg-primary"></span>
            <span className="ms-toggler-bar bg-primary"></span>
            <span className="ms-toggler-bar bg-primary"></span>
          </div>

          <div className="logo-sn logo-sm d-sm-none">
            <img src="../assets/img/x-tracked-logo-small.png" style={{height:'30px'}} alt="logo" />
          </div>
          <div className="logo-sn logo-sm d-none d-sm-block">
            <img src="../assets/img/x-tracked-logo-small.png" style={{height:'40px', margin: '15px'}} alt="logo" />
          </div>
                
        </nav>

        <div className="ms-content-wrapper">
          <Outlet />
          </div>
      </main>
        : <Login />}
    </div>
  );
}

const mapStateToProps = (state = {}) => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUserInfo,
    fetchIdToken,
    refreshIdToken,
  }
)(App);
