import { Actions } from "../constants";

export const fetchActivityDetail = (userActivityId) => ({
    type: Actions.ActivityDetail.FETCH_ACTIVITY_DETAIL,
    userActivityId
})

export const setActivityDetails = (userActivityData) => ({
    type: Actions.ActivityDetail.SET_ACTIVITY_DETAIL_DATA,
    userActivityData
})

export const createActivity = (userActivityPubId, date, practiceValue, competitiveValue, isPlanned, flag, comment) => ({
    type: Actions.ActivityDetail.CREATE_ACTIVITY,
    activity : {userActivityPubId, date, practiceValue, competitiveValue, isPlanned, flag, comment}
})

export const createActivities = (activities, userActivityPubID) => ({
    type: Actions.ActivityDetail.CREATE_USER_ACTIVITIES,
    activities : activities,
    userActivityPubID : userActivityPubID
})

export const createUserActivityShare = (userActivityPubId, shareToEmail, readOnly) => ({
    type: Actions.ActivityDetail.CREATE_USER_ACTIVITY_SHARE,
    shareInfo : {userActivityPubId, shareToEmail, readOnly}
})

export const removeUserActivityShare = (sharePubId) => ({
    type: Actions.ActivityDetail.REMOVE_USER_ACTIVITY_SHARE,
    sharePubId
})

export const removeUserActivityShareDone = (sharePubId) => ({
    type: Actions.ActivityDetail.REMOVE_USER_ACTIVITY_SHARE_DONE,
    sharePubId
})


export const setUserActivityShare = (share) => ({
    type: Actions.ActivityDetail.SET_USER_ACTIVITY_SHARE,
    shareInfo: share
})