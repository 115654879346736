import React, { useEffect, useState } from 'react';
import UserActivityPanel from './UserActivityPanel';
import AddUserActivity from './AddUserActivity';
import PreLoader from './Preloader';

function UserView(props) {
    var activityList = (<></>);
    var activeShares = (<></>);
    var pendingInvitations = (<></>);

    const {onFetchUserInfo} = props;

    const [addPanelOpen, setAddPanelOpen] = useState(false);

    useEffect(() => {
        onFetchUserInfo();
    }, [onFetchUserInfo]);


    if (!props.userInfo || props.userInfo.loading) {
        return (<PreLoader />)
    }

    let ua = props.userInfo.userActivities;
    let canCreateNewActivity = true;

    if (ua && ua.items) {
        activityList = ua.items.map(({ PubID, Name }) =>
                <UserActivityPanel key={PubID} userActivityID={PubID} loadState="ok" activityName={Name} onDelete={() => props.onDeleteUserActivity(PubID)} />
        );

        if(activityList.length >= ua.limit){
            canCreateNewActivity = false;
        }
    }

    let shares = props.userInfo.sharedWithMe;

    if (shares && shares.items) {
        pendingInvitations = shares.items.filter((x) => x.Pending).map(({ PubID, ReadOnly, UserActivity }) =>
            <div className="w-100 alert alert-warning alert-solid" role="alert">
                <strong>{UserActivity.User.Email}</strong> has requested to share <strong>{UserActivity.Name}</strong> {ReadOnly ? "(read only)" : ""} with you.
                &nbsp;&nbsp;<a href="#" onClick={() => props.onAcceptShare(PubID)}>Accept</a>
            </div>
        );

        activeShares = shares.items.filter((x) => !x.Pending).map(({ PubID, UserActivity }) =>
            <div key={PubID} className="w-100">
                <UserActivityPanel userActivityID={UserActivity.PubID} loadState="ok" activityName={`${UserActivity.User.Email} - ${UserActivity.Name}`} />

            </div>
        );
    }

    if (addPanelOpen) {
        return <div className="row"><AddUserActivity onAdd={props.onAddUserActivity} 
        defaultMeasure="Distance" 
        defaultMeasureName="Kms"
        defaultPreTimelineDays="28"
        defaultPostTimelineDays="28"
        onClose={() => setAddPanelOpen(false)} /></div>
    }

    return (<>
        <div className="row">
            {pendingInvitations.length === 0 && activeShares.length === 0 ? <></> :
                <div className="col-12 col-lg-6">
                    <div className="ms-panel">
                        <div className="ms-panel-header">
                            <h6>Shared with me</h6>
                        </div>
                        <div className="ms-panel-body">
                            {pendingInvitations}
                            {activeShares}

                        </div>
                    </div>
                </div>}
            <div className="col-12 col-lg-6">
                <div className="ms-panel">
                    <div className="ms-panel-header">
                        <h6>My activities</h6>
                    </div>
                    <div className="ms-panel-body">
                        {activityList.length === 0 || 1==2 ?
                            <><h3>Welcome to X-Tracked</h3>
                                
                                { // Users can only create new activities if they have not reached the limit
                                canCreateNewActivity ?
                                <>
                                <p>You have not added any activities</p>
                                <button className="btn btn-primary mt-4 d-block w-100" onClick={() => setAddPanelOpen(true)}  >
                                    Add your first activity now
                                </button>
                                </>
                                :
                                <button className="btn btn-primary mt-4 d-block w-100" disabled >
                                    Contact X-Tracked to add your first activity
                                    </button>
                                }
                            </> :
                            <>
                                {activityList}
                                { // Users can only create new activities if they have not reached the limit
                                canCreateNewActivity ?
                                <button className="btn btn-primary mt-4 d-block w-100" onClick={() => setAddPanelOpen(true)}  >
                                    New
                                </button>
                                :
                                <button className="btn btn-primary mt-4 d-block w-100" disabled >
                                    You have reached your allowed limit of activities ({ua.limit}). <br />Contact X-Tracked to increase your limit.
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default UserView;
