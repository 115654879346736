
import React, { useState } from 'react';

function ShareActivity(props) {
    const [shareToEmail, setShareToEmail] = useState();
    const [readOnly, setReadOnly] = useState(false);

    return (<>
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Share {props.activityContext.name}</h6>
                </div>
                <div className="ms-panel-body">
                    {props.activityContext.shares.length > 0 ?
                        <div className="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <td>Shared to</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.activityContext.shares.map((share) => {
                                        return <tr key={share.PubID}>
                                            <td style={{verticalAlign: 'middle', width: '80%'}}>{share.SharedTo}</td>
                                            <td style={{verticalAlign: 'middle'}}>
                                                {share.ReadOnly ? <span className="material-icons" style={{fontSize:'22px'}} title={'Read-only'}>visibility</span> : <></>}
                                                {share.Pending ? <span className="material-icons" style={{fontSize:'22px'}} title={'Share Invitation Pending'}>hourglass_bottom</span> : <></> }</td>
                                            <td>
                                                <button className="ms-btn-icon-outline btn-square btn-light" style={{border: 'none'}} onClick={(e) => {
                                                    e.preventDefault(); // We're inside a link, so we need to prevent that click redirecting us
                                                    window.confirm(`Are you sure you want to remove this share?`) && props.onRemoveShare(share.PubID)}}>
                                                    <i className="material-icons fs-16">delete</i>
                                                </button></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    :<></>}
                    <form className="needs-validation clearfix" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-row">
                            <div className="col-8">
                                <label htmlFor="share-to-email">Share to email</label>
                                <div className="input-group">
                                    <input type="text" class="form-control" required id="share-to-email" onChange={(e) => setShareToEmail(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="col-4">
                                <div> Read only </div>
                                <label className="ms-switch">
                                    <input type="checkbox" onChange={(e) => {setReadOnly(e.target.checked)}} />
                                    <span className="ms-switch-slider round"></span>
                                </label>
                                
                            </div>
                            
                        </div>
                        <button className="btn btn-success float-right" type="submit" onClick={() => { props.onShare(shareToEmail, readOnly); }}>Share</button>
                        <button className="btn btn-secondary float-left" onClick={props.onClose}>Close</button>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default ShareActivity;